import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

import BankLogo from '../images/bank.png'
import WireLogo from '../images/wire.png'
import PayoneerLogo from '../images/payoneer.png'
import PaypalLogo from '../images/paypal.png'
import StripeLogo from '../images/stripe.png'

export default function FooterComponent() {
  const date = new Date()
  return (
    <Footer>
      <Link to="/"><p>Technical Purple - {date.getFullYear()}</p></Link>
      <hr />
      <FooterInfo>
        <LegalInfo>
          <span className="legal">Technical Purple LLC</span>
          <span className="legal">8 The green, STE B, Dover, DE 19901</span>
          <span className="legal"><a href="mailto:admin@technicalpurple.com?Subject=Contact%20Support">admin@technicalpurple.com</a></span>
          <span><Link to="/terms">Terms</Link></span>
          <span><Link to="/privacy">Privacy</Link></span>
        </LegalInfo>
        <Payments>
          <img src={BankLogo} alt="payment method - bank"/>
          <img src={WireLogo} alt="payment method - wire"/>
          <img src={PayoneerLogo} alt="payment method - payoneer"/>
          <img src={PaypalLogo} alt="payment method - paypal"/>
          <img src={StripeLogo} alt="payment method - stripe"/>
        </Payments>
        <SocialInfo>
          <a href="https://twitter.com/technicalpurple">Twitter</a>
          <a href="https://www.instagram.com/technicalpurple/">Instagram</a>
          <a href="https://www.crunchbase.com/organization/technical-purple">Crunchbase</a>
          <a href="https://angel.co/company/technical-purple">AngelList</a>
        </SocialInfo>
      </FooterInfo>
    </Footer>
  )
}

const Footer = styled.footer`
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  background-color: #3E3347;
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  position: relative;

  a {
    color: #fff;
    text-decoration: none;
    margin: 0 30px;
  }

  hr {
    width: 100%;
    margin 20px 0;
    margin-top: 30px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px 50px;
  }
`
const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: #f9ed69;
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
`

const LegalInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  text-align: center;

  a, span {
    color: #fff;
    text-decoration: none;
    margin: 15px 0;
  }

  .legal {
    font-size: 14px;
    margin: 2px 0;
  }

  @media screen and (min-width: 1280px) {
     width: 33%;
     text-align: left;
  }
`

const Payments = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin: 30px 0;

  img {
    width: 60px;
    filter: invert(100%);
    padding: 5px;
  }

  @media screen and (min-width: 1280px) {
    width: 33%;
    margin: 0;
  }
`

const SocialInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  text-align: center;

  a, span {
    color: #fff;
    text-decoration: none;
    margin: 15px 0;
  }

  @media screen and (min-width: 1280px) {
     width: 33%;
  }
`