import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import brandLogo from "../images/tp_logo_5.png";

export default function Header() {
  return (
    <HeaderTag>
      <Link to="/">
        <BrandLogoWrapper><img src={brandLogo} alt="Brand logo" /></BrandLogoWrapper>
      </Link>
      <Navbar>
        <Link to="/who-we-are">
          <span>US</span>
        </Link>
        <Link to="/what-we-do">
          <span>WORK</span>
        </Link>
        <Link to="/how-it-works">
          <span>HOW</span>
        </Link>
        <Link to="/#contact-section">
          <span>CONTACT</span>
        </Link>
      </Navbar>
    </HeaderTag>
  )
}

export const HeaderTag = styled.header`
  display: flex;
  height: auto;
  background-color: transparent;
  padding: 40px 50px;
  justify-content: space-between;
`

export const BrandLogoWrapper = styled.div`
  display: flex;
  height: 80px;
  width: 80px;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
`

export const Navbar = styled.nav`
  display: none;
  height: 100%;
  font-weight: 700;
  color: #F9ED69;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  
  span {
    margin-left: 60px;
    cursor: pointer;
  }

  a {
    color: #F9ED69;
    text-decoration: none;
  }

  @media screen and (min-width: 1280px) {
    display: flex;
  }
`