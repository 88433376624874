import React, { useState } from 'react'
import styled from "styled-components"

import { H2Section } from '../styles/shared.styles'

export default function GetInTouch() {
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [messageInput, setMessageInput] = useState('');

  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  const [sendingMessage, setSendingMessage] = useState(false);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSuccess(false);
    setFormError(false);
    setSendingMessage(true);

    if (nameInput && emailInput && messageInput) {
      fetch('https://script.google.com/macros/s/AKfycbysCW0Z4X_NiIs2ALTKDuGPD1BzvobmKhZpYanqaktm4EfjyTF5q1VjGDmParoUd6-D/exec', {
        method: "POST",
        body: JSON.stringify({
          email: emailInput,
          name: nameInput,
          message: messageInput,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          setFormSuccess(true);
          setFormError(false);
          setSendingMessage(false);
        })
        .catch(() => {
          setFormError(true);
          setFormSuccess(false);
          setSendingMessage(false);
        });
    }
  }

  return (
    <GetInTouchWrapper id="contact-section">
      <TextWrapper>
        <H2Section>Get in Touch</H2Section>
        <p>Just let us know whatever you need.</p>
      </TextWrapper>
      <ContactFormWrapper>
        <ContactInfo>
          <span><strong>Technical Purple LLC</strong></span>
          <span>8 The green, STE B, Dover, DE 19901</span>
          <span><a href="mailto:admin@technicalpurple.com?Subject=Contact%20Support">admin@technicalpurple.com</a></span>
          <span>
            <a href="https://twitter.com/technicalpurple">Twitter</a> | <a href="https://www.instagram.com/technicalpurple/">Instagram</a> | <a href="https://www.crunchbase.com/organization/technical-purple">Crunchbase</a> | <a href="https://angel.co/company/technical-purple">AngelList</a>
          </span>
        </ContactInfo>
        <form onSubmit={handleFormSubmit}>
          <input type="text" placeholder='Name' value={nameInput} onChange={(e) => setNameInput(e.target.value)} required />
          <input type="email" placeholder='Email' value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required />
          <textarea placeholder='Write your message here' value={messageInput} onChange={(e) => setMessageInput(e.target.value)} required/>
          <button type="submit" disabled={sendingMessage}>Send</button>
        </form>

        {formSuccess && !formError && <SuccessMessage>Thank you! - Your message was sent.</SuccessMessage>}
        {formError && !formSuccess && <ErrorMessage>There was a problem. Please, try again later.</ErrorMessage>}
      </ContactFormWrapper>
    </GetInTouchWrapper>
  )
}

const GetInTouchWrapper = styled.section`
  display: flex;
  padding: 100px 30px;
  flex-direction: column;
  background-color: #6A2C70;
  color: #fff;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: #fff;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1280px) {
    padding: 200px 50px;
    flex-direction: row;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  margin-bottom: 40px;

  @media screen and (min-width: 1280px) {
    width: 50%;
    text-align: left;
  }
`
const SuccessMessage = styled.div`
  margin: 30px 0;
  color: #3fbd9f;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  text-align: center;
`
const ErrorMessage = styled.div`
  margin: 30px 0;
  color: #B83B5E;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  text-align: center;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;

  strong {
    font-weight: 600;
  }

  a {
    color: #f9ed69;
    text-decoration: none;
  }
`
const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  input, textarea {
    height: 40px;
    border-radius: 4px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    padding: 10px;
  }

  textarea {
    min-height: 100px;
  }

  button {
    height: 40px;
    margin-top: 20px;
    border-radius: 3px;
    outline: none;
    border: none;
    color: #333;
    font-size: 20px;
    font-weight: 700;
    background: #f9ed69;
    cursor: pointer;
  }

  button:hover {
    background: #BDB85C;
  }

  @media screen and (min-width: 1280px) {
    width: 50%;
  }
}
`