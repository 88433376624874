import * as React from "react"
import styled from "styled-components"

// Styles
import '../styles/reset.css'
import { MainComponent } from '../styles/shared.styles'
// Components
import SEO from '../components/SEO'
import Header from '../components/Header'
import Hero from '../components/Hero'
import UsSection from '../components/UsSection'
import WorkSection from '../components/WorkSection'
import HowSection from '../components/HowSection'
import GetInTouch from '../components/GetInTouch'
import Footer from '../components/Footer'

const IndexPage = () => {
  return (
    <MainComponent>
      <SEO />
      <HeroContainer id='hero-section'>
        <Header />
        <Hero />
      </HeroContainer>
      <UsSection />
      <WorkSection />
      <HowSection />
      <GetInTouch />
      <Footer />
    </MainComponent>
  )
}

export default IndexPage

export const HeroContainer = styled.section`
  display: block;
  background-color: #6A2C70;
`
