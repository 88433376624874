import React, { useRef } from 'react'
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function Hero() {
  const particlesContainer = useRef(null);

  const particlesInit = async (main: any) => {
    await loadFull(main);
  };

  return (
    <HeroWrapper ref={particlesContainer}>
      <ContentWraper>
        <Jumbotron>
          <h1>PREMIUM Software development services</h1>
          <p>Hello! We're <span>Technical Purple</span>. A remote, group of talented, experienced Software Engineers. We build your dreams.</p>
          <p>We are kind. <AnchorLink to="/#contact-section"><span>Get in touch</span></AnchorLink></p>
        </Jumbotron>
        <Polygons>
          <Particles
            id="tsparticles"
            init={particlesInit}
            container={particlesContainer}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#F9ED69",
                },
                links: {
                  color: "#F9ED69",
                  distance: 100,
                  enable: true,
                  opacity: 0.2,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 900,
                  },
                  value: 20,
                },
                opacity: {
                  value: 0.2,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
        </Polygons>
      </ContentWraper>
      <ColorSection />
    </HeroWrapper>
  )
}

export const HeroWrapper = styled.div`
  display: block;
  position: relative;
  height: 70vh;

  @media screen and (min-width: 1280px) {
    height: 50vh;
  }
`
const ColorSection = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 10px;
  background: #6a2c70;
`
export const ContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0 50px;
  align-items: center;
  justify-content: space-around;
`
export const Jumbotron = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    max-width: 77%;
    color: #fff;
  }

  p {
    font-size: 20px;
    margin-top: 25px;
    color: #fff;
    font-weight: 500;
  }

  p span {
    color: #40BD9F;
    font-weight: 700;
    font-size: 24px;
  }

  a {
    color: #40BD9F;
    text-decoration: none;
  }

  a span:hover {
    color: #F9ED69;
  }

  @media screen and (min-width: 1280px) {
    h1 {
      font-size: 4rem;
    }
  }
`
export const Polygons = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
`